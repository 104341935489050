import Box from '@mui/material/Box'
import cn from '@utils/helpers/cn'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'

const MuiBox = ({ blok }) => {
  return (
    <Box
      component={blok.componentType}
      className={cn(blok.class)}
      {...storyblokEditable(blok)}
    >
      {blok.blocks
        ?.filter(
          (b) =>
            (b.hasOwnProperty('showBlock') && b.showBlock) ||
            !b.hasOwnProperty('showBlock'),
        )
        .map((nestedBlok, index) => (
          <StoryblokComponent
            index={index}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
    </Box>
  )
}

export default MuiBox
